import React from 'react'
import { Link,StaticQuery,graphql } from 'gatsby'
import Post from '../components/post'
import Layout from '../components/layout'
import Cta from "../components/cta"
import Metatags from '../components/Metatags'

const FeaturesPage = () => (
    <Layout>  
   
   <Metatags
           title={`Email Sign-Up Pop-up | Optinly`}
           description={'Increase lead generation with the email sign-up pop-up builder. Optinly is a drag-and-drop pop-up builder that increases user engagement and conversion rates.'} 
            keywords={[`woocommerce abandoned cart recovery`, `shopify customer retention`, `woocommerce sales boost techniques`, `Customer retention strategies`, `customer retaining techniques`, `boost eCommerce sales`, `retention marketing`, `retention marketing`, `acquisition marketing`]}
            url = "https://www.optinly.com/features/"
            pathname = "/features/"
        />
      <div className="features-banner ">  
      <div className="breadcrumb_top">
            <div className="container">
              <div className="text-center">
                  <h1>Web Popups to Grow Your Business</h1>
                  <p>A growth-focused popup plugin that allows you to choose your marketing goals. Lead Generation, Website
                    Conversions, Polls, Customer Retention and more using one popup builder. Without super developers or
                    coding.</p>            
                  <Cta url="https://app.optinly.com" rel="noopener" target="_blank">Try Optinly for Free</Cta>
              </div>
            </div>
        </div>    
        <StaticQuery
          query={FeturesQuery}
          render={data => {
            return (
              <Post
                title={data.markdownRemark.frontmatter.title}
                description={data.markdownRemark.frontmatter.description}
                keywords={data.markdownRemark.frontmatter.keywords}
                excerptData={data.markdownRemark.htmlAst}
              />
            )
          }}
        />
      </div>   
    </Layout>
)

const FeturesQuery = graphql`
query FeturesQuery {
  markdownRemark(frontmatter: {path: {eq: "/features"}}){
    htmlAst
    frontmatter{
      title
      description
      keywords
      path
    }
  }
}
`
export default FeaturesPage
